import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Dot from './dot'
import Link from './link'
import Button from './button'
import { MenuContext } from '../context/menu-provider'
import { ProjectHeader } from './shared/project-thumbnail'
import ImageZoom from './image-zoom'
import FadeIn from './fade-in'

export default function Projects({ projects }) {
    return (
        <>
            <div className="pt-[40px] mb-[60px] bt1 md:pt-[30px] md:mb-[90px]">
                <h4 className="h4 md:text-black">more projects</h4>
            </div>
            <div className='grid grid-cols-3 gap-x-[15px] gap-y-[40px] mb-[130px] md:grid-cols-1 md:gap-y-[50px] md:mb-[160px] '>
                {projects.map((project) => {
                    return <ProjectThumbNail project={project} />
                })}
                <Cta />
            </div>
        </>

    )
}


const Wrapper = styled.div`
height:100%;
display:flex ;
flex-direction:column;
justify-content: space-between;
`

function ProjectThumbNail({ project }) {



    return (
        <FadeIn>
        <Link to={`projects/${project.slug}`}>
            <Wrapper >
                <div className='grid grid-cols-4 mb-[20px] md:hidden'>
                    <div className='flex align-center col-span-3'>
                        <h3 className='h3 flex align-center mr-[15px] '>{project.name}</h3>
                    </div>
                    <div className='flex align-center  justify-between'>
                        <h4 className='h4 flex items-center  mr-[15px] '>VIEW</h4><Dot wrapper={Wrapper} />
                    </div>
                </div>
                <ProjectHeader project={project} />
                <ImageZoom ratio='66.66%' wrapper={Wrapper}>
                    <GatsbyImage className='h-[100%]' image={project.featuredImage.gatsbyImageData} />
                </ImageZoom>
            </Wrapper>
        </Link>
        </FadeIn>
    )
}

function Cta() {
    const { setOpen } = useContext(MenuContext)
    return (
        <div className=' justify-end flex flex-col md:pt-[100px] '>
            <div className='relative pt-[66.66%] md:pt-[0px] '>
                <div className='bg-[#E6E6E6] justify-center items-center flex flex-col flex-grow md:bg-transparent absolute top-0 w-full h-full p-[5px] md:relative'>
                <h3 className='h3 mb-[30px]'>Your next project?</h3>
                <Button dark={true} onClick={() => setOpen(true)} text='Get in touch' />
                </div>
            </div>
        </div>
    )
}