import React from "react"

const Arrow = ({ direction = "right" }) => {
  const isLeft = direction === "left"

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: isLeft ? "rotate(180deg)" : "none" }}
    >
      <mask id="path-1-inside-1_2154_346" fill="white">
        <path d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z" />
      </mask>
      <path
        d="M27.3536 20.3536C27.5488 20.1583 27.5488 19.8417 27.3536 19.6464L24.1716 16.4645C23.9763 16.2692 23.6597 16.2692 23.4645 16.4645C23.2692 16.6597 23.2692 16.9763 23.4645 17.1716L26.2929 20L23.4645 22.8284C23.2692 23.0237 23.2692 23.3403 23.4645 23.5355C23.6597 23.7308 23.9763 23.7308 24.1716 23.5355L27.3536 20.3536ZM13 20.5L27 20.5L27 19.5L13 19.5L13 20.5ZM39 20C39 30.4934 30.4934 39 20 39V41C31.598 41 41 31.598 41 20H39ZM20 39C9.50659 39 1 30.4934 1 20H-1C-1 31.598 8.40202 41 20 41V39ZM1 20C1 9.50659 9.50659 1 20 1V-1C8.40202 -1 -1 8.40202 -1 20H1ZM20 1C30.4934 1 39 9.50659 39 20H41C41 8.40202 31.598 -1 20 -1V1Z"
        fill="#3D3D3D"
        mask="url(#path-1-inside-1_2154_346)"
      />
    </svg>
  )
}

export default Arrow
