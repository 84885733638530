import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { StructuredText } from "react-datocms"
import FadeIn from "./fade-in"
import InsetTitle from "./inset-title"

export default function Apporach({ apporach }) {
  return (
    <section id="team" className="mb-[160px] md:mb-[60px]">
      {apporach.map((item, index) => (
        <FadeIn key={item.id}>
          <div
            className={`grid grid-cols-2 mb-[60px] ${
              index === 0 ? "" : ""
            } pt-[40px] gap-x-[40px] md:pt-[30px] md:grid-cols-1`}
          >
            <div className="flex flex-col justify-between">
              <InsetTitle title={item.overline} content={item.title} />
              <div className=" mt-[30px] md:mt-[60px]">
                <div className="hidden md:block mb-[60px] ">
                  <GatsbyImage image={item.image.gatsbyImageData} />
                </div>
                <div className="p strut-text max-w-[500px]">
                  <StructuredText data={item.content} />
                </div>
                <div
                  className="p op-50 mt-[60px] content max-w-[500px]"
                  dangerouslySetInnerHTML={{
                    __html: item.additonalInformation,
                  }}
                />
              </div>
            </div>
            <div className="md:hidden">
              <GatsbyImage image={item.image.gatsbyImageData} className="aspect-[0.7] w-full object-cover" />
            </div>
          </div>
        </FadeIn>
      ))}
    </section>
  )
}
