import { graphql } from "gatsby"
import React, { useMemo } from "react"
import About from "../components/about"
import Apporach from "../components/apporach"
import Cta from "../components/cta"
import FadeIn from "../components/fade-in"
import FeaturedProject from "../components/featuredProject"
import Hero from "../components/hero"
import HomeFooter from "../components/home-footer"
import Layout from "../components/layout"
import MoveUp from "../components/move-up"
import OngoingProjects from "../components/ongoingProjects"
import Projects from "../components/projects"
import Quote from "../components/quote"
import Seo from "../components/seo"
import Services from "../components/services"
import { ContentContainer } from "../components/styles"

const IndexPage = ({ data: { home, projects } }) => {
  const reflection = {
    quote: home.clientQuote,
    client: home.clientName,
    image: home.clientImage,
  }

  const services = {
    title: home.servicesTitle,
    delivery: home.projectDelivery,
    planning: home.projectPlanning,
  }

  const apporach = {
    title: home.approachTitle,
    image: home.approachImage,
    content: home.approachContent,
  }

  const filteredProjects = useMemo(() => {
    return projects.nodes.filter(
      project => project.name !== home.featuredProject.name
    )
  }, [projects, home.featuredProject.name])

  return (
    <Layout>

      <Seo
        title="FormPM (Form PM) | Brisbane Project Management Experts"
        description="FormPM (Form PM) delivers expert project management and development assistance for construction projects across Brisbane. Trusted by developers and builders, we make complex projects simple."
      />
      <Hero image={home.heroBanner}>

        <MoveUp />
      </Hero>

      <ContentContainer className="pt-[50px]">
        <FadeIn>
          <About
            title={home.aboutUsTitle}
            content={home.aboutUsContent}
            image={home.aboutUsImage}
          />
        </FadeIn>
        <FadeIn>
          <Apporach apporach={home.approach} />
        </FadeIn>
        <FadeIn>
          <FeaturedProject
            project={home.featuredProject}
            content={home.featuredProjectContent}
            className="pb-[80px] bt1 md:pb-[30px]"
          />
        </FadeIn>

        <Projects projects={filteredProjects} />
      </ContentContainer>
      <OngoingProjects projects={home.ongoingProjects} />
      <Quote reflection={reflection} />
      <ContentContainer>
        <FadeIn>
          <Services services={services} />
        </FadeIn>
        <FadeIn>
          <Cta />
        </FadeIn>
      </ContentContainer>
      <HomeFooter />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query Homepage {
    home: datoCmsHomePage {
      heroBanner {
        gatsbyImageData
      }
      aboutUsTitle
      aboutUsContent {
        value
      }
      aboutUsImage {
        gatsbyImageData
      }
      featuredProject {
        name
        date
        category
        slug
        featuredImage {
          gatsbyImageData
        }
      }
      featuredProjectContent {
        value
      }
      clientName
      clientQuote
      clientImage {
        gatsbyImageData
      }
      ongoingProjects {
        id
        name
        information {
          id
          title
          content
        }
      }
      servicesTitle
      projectDelivery {
        content
      }
      projectPlanning {
        content
      }
      approach {
        id
        title
        overline
        content {
          value
        }
        additonalInformation
        image {
          gatsbyImageData
        }
      }
    }
    projects: allDatoCmsProject(sort: { order: ASC, fields: position }) {
      nodes {
        position
        id
        name
        slug
        date
        category
        featuredImage {
          gatsbyImageData
        }
      }
    }
  }
`
