import React from "react"
import Arrow from "./icons/Arrow"
import { ContentContainer } from "./styles"

import "swiper/css"
import "swiper/css/navigation"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import FadeIn from "./fade-in"

const OngoingProjects = ({ projects }) => {
  return (
    <FadeIn>
      <section className="pb-[100px]">
        <ContentContainer>
          <div className="pt-[40px] flex justify-between mb-[60px] bt1 md:pt-[30px] md:mb-[90px]">
            <h4 className="h4 md:text-black">ongoing projects</h4>

            <div className="flex navigation gap-x-[15px]">
              <button className="prev-btn">
                <Arrow direction="left" />
              </button>
              <button className="next-btn">
                <Arrow direction="right" />
              </button>
            </div>
          </div>
        </ContentContainer>
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          slidesPerView={3.2}
          spaceBetween={20}
          loop
          breakpoints={{
            0: {
              slidesPerView: 1.25,
              slidesOffsetBefore: 30,
              slidesOffsetAfter: 30,
            },
            768: {
              slidesOffsetBefore: 60,
              slidesOffsetAfter: 60,
              slidesPerView: 3.2,
            },
          }}
          className=""
        >
          {projects.map(project => (
            <SwiperSlide key={project.id} className="flex h-[100%]">
              <ProjectCard project={project} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </FadeIn>
  )
}

export default OngoingProjects

const ProjectCard = ({ project }) => {
  return (
    <div className="p-[30px] h-[100%] mb-[10px] bg-white project-card">
      <h3 className="h3 mb-[90px] md:mb-[60px]">{project.name}</h3>

      <div className="flex flex-col gap-y-[10px]">
        {project.information &&
          project.information.map(item => (
            <div key={item.id}>
              <h4 className="h4">{item.title}</h4>
              <h4 className="h4 black">{item.content}</h4>
            </div>
          ))}
      </div>
    </div>
  )
}
