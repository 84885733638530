import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { StructuredText } from "react-datocms"
import InsetTitle from "./inset-title"

export default function About({ title, content, image }) {
  return (
    <>
      <div id="about" className="grid grid-cols-2 bt1 mb-[170px] md:mb-[60px] pt-[40px] gap-x-[40px] md:pt-[30px] md:grid-cols-1">
        <div className="flex flex-col justify-between">
          <InsetTitle title="ABOUT US" content={title} />
          <div className=" mt-[30px] md:mt-[60px]">
            <div className="hidden md:block mb-[60px] ">
              <GatsbyImage image={image.gatsbyImageData} />
            </div>
            <div className="p strut-text max-w-[500px]">
              <StructuredText data={content} />
            </div>
          </div>
        </div>
        <div className="md:hidden mla">
          <GatsbyImage image={image.gatsbyImageData} className="aspect-[0.7] w-full object-cover" />
        </div>
      </div>
    </>
  )
}
